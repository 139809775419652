import { CurrencyInput } from '@src/components/horizon/CurrencyInput';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import './PaymentAddCard.scss';
import { useMemo, useRef, useState } from 'react';
import '@horizon/icons/individual/hzn-trash';
import '@horizon/icon-button/hzn-icon-button';
import {
    BasketDto,
    PaymentDto,
    UpdatePaymentRequest,
    useAddBasketPaymentMutation,
    useUpdatePaymentMutation,
} from '@src/api/CheckoutApi';
import { FlexRow } from '@src/components/Flex';
import { InputHandle } from '@src/components/horizon/Input';
import { PaymentSelector } from './PaymentSelector';
import { resolvePaymentValidations } from '@src/utils/BasketFns';

export type PaymentTypes = 'CASH' | 'CHECK' | 'DEBIT' | 'CERTIFIED_FUNDS';

export interface PaymentAddCardProps {
    basket: BasketDto;
    partyId: string;
    payment?: PaymentDto;
    balance: number;
    onClose: () => void;
}

export const PaymentAddCard = ({ basket, partyId, payment, balance, onClose }: PaymentAddCardProps) => {
    const [addPayment, addPaymentState] = useAddBasketPaymentMutation();
    const [updatePayment, updatePaymentState] = useUpdatePaymentMutation();

    const currencyRef = useRef<InputHandle>();
    const [paymentType, setPaymentType] = useState<PaymentTypes | undefined>((payment?.paymentType as PaymentTypes));
    const [paymentAmount, setPaymentAmount] = useState<number | undefined>(payment?.amount);
    const paymentValidations = useMemo(
        () => resolvePaymentValidations(basket, payment, paymentType, balance),
        [payment, paymentType, balance]
    );

    const onSavePayment = () => {
        if (!currencyRef.current.validate()) return;
        if (!payment) {
            addPayment({
                basketId: basket.id,
                body: {
                    paymentType,
                    amount: paymentAmount,
                    lineOfBusinessProvided: false,
                    direction: 'INBOUND',
                    partyRole: {
                        partyId,
                        relationship: 'SELLER',
                    },
                },
            })
                .unwrap()
                .then(() => onClose())
                .catch(err => console.log('Save payment error', err));
        } else {
            updatePayment({
                basketId: basket.id,
                paymentId: payment.id,
                body: { paymentType, amount: paymentAmount } as UpdatePaymentRequest,
            })
                .unwrap()
                .then(onClose)
                .catch(err => console.log('Update payment error', err));
        }
    };

    return (
        <>
            <FlexRow className={'align-items-top flex-fill'}>
                <PaymentSelector 
                    basket={basket} 
                    payment={payment} 
                    value={paymentType} 
                    onValueChanged={setPaymentType} 
                />
                <CurrencyInput
                    ref={currencyRef}
                    value={paymentAmount}
                    required
                    min={paymentValidations.min}
                    max={paymentValidations.max}
                    errorMessages={paymentValidations.errorMessages}
                    onValueChanged={setPaymentAmount}
                    className="w-50"
                >
                    Amount
                </CurrencyInput>
            </FlexRow>
            <FlexRow>
                <HorizonButton
                    compact
                    variant="primary"
                    onClick={() => onSavePayment()}
                    loading={addPaymentState.isLoading || updatePaymentState.isLoading}
                >
                    Save Payment
                </HorizonButton>
                <HorizonButton
                    compact
                    variant="secondary"
                    onClick={() => onClose()}
                    disabled={addPaymentState.isLoading || updatePaymentState.isLoading}
                >
                    Cancel
                </HorizonButton>
            </FlexRow>
        </>
    );
};


