import React from 'react';
import { CardRow } from '../../../../components/Card';
import { AsCurrency, PaymentTypeName } from '@src/utils/Formatting';
import { HorizonButton } from '../../../../components/horizon/HorizonButton';
import { PaymentDto } from '@src/api/CheckoutApi';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectCardReaderStatus, selectCashDrawerStatus } from '@src/store/HardwareSlice';
import { setPayment } from '@src/store/PaymentSlice';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { FlexColumn, FlexRow } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';

export interface PaymentCardProps {
    payment: PaymentDto;
    basketIsBalanced: boolean;
}

export const PaymentReadCard = ({ payment, basketIsBalanced }: PaymentCardProps) => {
    const dispatch = useAppDispatch();
    const cardReaderStatus = useAppSelector(selectCardReaderStatus);
    const cashDrawerStatus = useAppSelector(selectCashDrawerStatus);
    
    // in order to process a payment:
    // 1. the negative equity balance must be accounted for
    // 2. a cash drawer must be assigned to this workstations
    // 3. if this is a debit payment the card reader hardware must be functional
    // note: we don't explicitly check for functional cash drawer hardware because BOAs/BOMs can manually open the cash drawer with a key.
    const canProcessPayment = basketIsBalanced && !!cashDrawerStatus.assigned && (payment.paymentType !== "DEBIT" || !!cardReaderStatus.ready)

    return ( <>
        <FlexColumn className="gap-2">
            {(payment.status !== "REFUNDED" && (
                <CardRow label="Amount" value={<span className="stress">{AsCurrency(payment.amount)}</span>}/>
            ))}
            {payment.status === "REFUNDED" && (<>
                {payment.paymentType === 'CERTIFIED_FUNDS' && (
                    <CardRow label="Mail refund amount due" value={<span className="stress">{AsCurrency(payment.checkAmount)}</span>} />
                )}
                {payment.paymentType !== 'CERTIFIED_FUNDS' && ( 
                    <CardRow label="Mail refund amount due" value={<span className="stress">{AsCurrency(payment.amount)}</span>} />
                )}
            </>)}
            {(payment.status === "PAID" || payment.status === "VOIDED" || payment.status === "REFUNDED") && (<>
                {payment.paymentType === 'CASH' && !!payment.cashCollected && (
                    <CardRow label="Change given" value={<span className="stress">{AsCurrency(payment.cashCollected - payment.amount)}</span>} />
                )}
                {payment.paymentType === 'DEBIT' && !!payment.cardSuffix && (
                    <CardRow label="Card Number" value={`...${payment.cardSuffix}`} />
                )}
                {payment.paymentType === 'CERTIFIED_FUNDS' && !!payment.checkAmount && (
                    <CardRow label="Check Amount" value={AsCurrency(payment.checkAmount)} />
                )}
                {(payment.paymentType === 'CHECK' || payment.paymentType === 'CERTIFIED_FUNDS') && !!payment.checkNumber && (
                    <CardRow label="Check Number" value={payment.checkNumber} />
                )}       
            </>)}
            <HorizonAlert 
                show={(payment.status === "PAID" && (payment.paymentType === 'CHECK' || payment.paymentType === 'CERTIFIED_FUNDS'))}
                tone="info" 
                className='pt-3'
                heading="Keep check for scanning."
                sub-heading='Please hold onto the check you are currently processing. You will be prompted to scan a copy of this check in a later step.' 
            /> 
            <HorizonAlert 
                show={payment.status === "REFUNDED"}
                tone="info" 
                className='pt-3'
                heading="Mail refund will be issued automatically."
                sub-heading='The refund due to customer will be mailed as a refund check, per CarMax policy. Customers should allow up to 14 business days to receive their refund.' 
            /> 
        </FlexColumn>
        {payment.status == 'NEW' && (
            <FlexRow className='pt-3'>
                <HorizonButton
                    compact
                    variant="primary"                
                    onClick={() => dispatch(setPayment({payment, mode: "Process"}))}
                    disabled={!canProcessPayment}
                >
                    Process Payment
                </HorizonButton>
            </FlexRow>
        )
        }
        {(payment.status == 'SUBMITTED' || payment.status == 'PAID') && (
            <FlexRow className='pt-3'>
                <HorizonButton 
                    compact 
                    variant="secondary" 
                    tone="critical" 
                    onClick={() => {dispatch(setPayment({payment, mode: "Void"}))}}
                >
                    Void Payment
                </HorizonButton>
            </FlexRow>
        )}
    </>);
};
