import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import {
    CheckPaymentDetails,
    reset,
    selectPayment,
    setPaymentDetails,
    setProcessStep,
    setShouldRefund,
} from '@src/store/PaymentSlice';
import { Modal } from 'react-bootstrap';
import { ProcessPayment } from '../Common/ProcessPayment';
import { ConfirmCheckAmount } from './ConfirmCheckAmount';
import { CheckPaymentDescription } from './CheckPaymentDescription';
import { ReceiveCheck } from '../Common/ReceiveCheck';
import { InitiateCheckReturn } from '../Common/InitiateCheckReturn';
import { ConfirmCheckReturn } from '../Common/ConfirmCheckReturn';
import { ConfirmMailRefund } from '../Common/ConfirmMailRefund';

/**
 * ProcessCheckModal is a modal wizard for processing check payments.
 * This component provides the modal shell, presents the modal content for
 *   each wizard step, and orchestrates control flow for the steps.
 */
export const ProcessCheckModal = ({ show }: { show: boolean }) => {
    const dispatch = useAppDispatch();
    const { mode, step, payment } = useAppSelector(selectPayment);

    return (
        <Modal centered size="sm" show={show}>
            {mode === 'Process' && (
                <>
                    {step === 'Start' && (
                        <ConfirmCheckAmount
                            onContinue={checkNumber => {
                                dispatch(setPaymentDetails({ type: 'CHECK', checkNumber } as CheckPaymentDetails));
                                dispatch(setProcessStep('HandleCheck'));
                            }}
                            onCancel={() => dispatch(reset())}
                        />
                    )}
                    {step === 'HandleCheck' && (
                        <ReceiveCheck
                            onContinue={() => dispatch(setProcessStep('Finish'))}
                            onCancel={() => dispatch(reset())}
                        >
                            <CheckPaymentDescription />
                        </ReceiveCheck>
                    )}
                </>
            )}
            {mode === 'Void' && (
                <>
                    {step === 'Start' && (
                        <InitiateCheckReturn
                            onContinue={() => dispatch(setProcessStep('HandleCheck'))}
                            onCancel={() => dispatch(reset())}
                        >
                            <CheckPaymentDescription />
                        </InitiateCheckReturn>
                    )}
                    {step === 'HandleCheck' && (
                        <ConfirmCheckReturn
                            onContinue={checkReturned => {
                                dispatch(setShouldRefund(!checkReturned));
                                dispatch(setProcessStep(checkReturned ? 'Finish' : 'ConfirmRefund'));
                            }}
                            onCancel={() => dispatch(reset())}
                        />
                    )}
                    {step === 'ConfirmRefund' && (
                        <ConfirmMailRefund
                            refundAmount={payment.amount}
                            onConfirm={() => dispatch(setProcessStep('Finish'))}
                            onCancel={() => dispatch(setProcessStep('HandleCheck'))}
                        />
                    )}
                </>
            )}
            {step === 'Finish' && (
                <ProcessPayment onComplete={() => dispatch(reset())} onCancel={() => dispatch(reset())}>
                    <CheckPaymentDescription />
                </ProcessPayment>
            )}
        </Modal>
    );
};
