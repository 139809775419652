import React, { useMemo } from 'react';
import { Card, CardRow } from './Card';
import { AsCurrency } from '@src/utils/Formatting';
import {
    getChangeGivenAmount,
    getNegativeEquityBalance,
    getTotalEquity,
    getVehicleName,
    getVehicleStockNumber,
    getVehicleVin,
} from '@src/utils/BasketFns';
import { BasketDto } from '@src/api/CheckoutApi';
import { FlexColumn } from './Flex';

export const CartDetails = ({ basket }: { basket: BasketDto }) => {
    const totalEquity = useMemo(() => getTotalEquity(basket), [basket]);
    const balanceDue = useMemo(() => (totalEquity >= 0 ? totalEquity : getNegativeEquityBalance(basket)), [basket]);
    const changeGiven = useMemo(() => getChangeGivenAmount(basket), [basket])

    return (
        <Card>
            <FlexColumn>
                <CardRow
                    label={
                        <hzn-text weight="bold" size="small">
                            CHECKOUT
                        </hzn-text>
                    }
                    value={
                        // TODO: cart states aren't yet hashed out. figure out what this should look like
                        // after those requirements are established and codified in the API.
                        // see example of paymentStateBadge for UI guidance when this is available.
                        <hzn-badge label="In Progress" tone="info" />
                    }
                />
                <div>
                    <hzn-text weight="bold" size="large">
                        {basket.basketOwnerName}
                    </hzn-text>
                </div>
                <hzn-divider />
                <FlexColumn className="gap-1">
                    <CardRow className='pb-2'
                        label="Appraisal purchase" 
                        value={getVehicleName(basket)} />
                    <CardRow
                        label="VIN"
                        value={<hzn-copy-text content={getVehicleVin(basket)} highlight-count="6" copy-label="Copy VIN" />} />
                    <CardRow
                        label="Stock #"
                        value={<hzn-copy-text content={getVehicleStockNumber(basket)} copy-label="Copy Stock Number" />} />
                </FlexColumn>
                <hzn-divider />
                <CardRow
                    label={`Total due to ${totalEquity <= 0 ? 'CarMax' : 'Customer'}`}
                    value={AsCurrency(Math.abs(totalEquity))} />
                <CardRow 
                    label="Processed payments" 
                    value={AsCurrency(basket.customerProcessedPaymentAmount)} />
                <CardRow 
                    label="Change given" 
                    value={AsCurrency(changeGiven)} />
                <CardRow
                    label={`Balance due to ${totalEquity <= 0 ? 'CarMax' : 'Customer'}`}
                    value={<span className="stress">{AsCurrency(balanceDue)}</span>} />
            </FlexColumn>
        </Card>
    );
};
