import { CardRow } from '@src/components/Card';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment, CheckPaymentDetails } from '@src/store/PaymentSlice';
import { AsCurrency } from '@src/utils/Formatting';

export const CheckPaymentDescription = () => {
    const { payment, paymentDetails } = useAppSelector(selectPayment);
    const checkDetails = paymentDetails as CheckPaymentDetails;

    return (
        <>
            <CardRow className='pt-3 ps-2 pe-2' label={<hzn-text tone="subdued">Payment method</hzn-text>} value="Check" />
            <hzn-divider />
            <CardRow className='ps-2 pe-2' label={<hzn-text tone="subdued">Amount</hzn-text>} value={AsCurrency(payment.amount)} />
            {checkDetails && (
                <>
                    <hzn-divider />
                    <CardRow className='ps-2 pe-2' label={<hzn-text tone="subdued">Check Number</hzn-text>} value={checkDetails.checkNumber} />
                </>
            )}
        </>
    );
};
