import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { CertifiedFundsPaymentDetails, reset, selectPayment, setPaymentDetails, setProcessStep, setShouldRefund } from '@src/store/PaymentSlice';
import { Modal } from 'react-bootstrap';
import { ProcessPayment } from '../Common/ProcessPayment';
import { ConfirmCertifiedFundsAmount } from './ConfirmCertifiedFundsAmount';
import { CertifiedFundsPaymentDescription } from './CertifiedFundsPaymentDescription';
import { ReceiveCheck } from '../Common/ReceiveCheck';
import { InitiateCheckReturn } from '../Common/InitiateCheckReturn';
import { ConfirmCheckReturn } from '../Common/ConfirmCheckReturn';
import { ConfirmMailRefund } from '../Common/ConfirmMailRefund';

/**
 * ProcessCertifiedFundsModal is a modal wizard for processing certified funds payments.
 * This component provides the modal shell, presents the modal content for
 * each wizard step, and orchestrates control flow for the steps.
 */
export const ProcessCertifiedFundsModal = ({ show }: { show: boolean }) => {
    const dispatch = useAppDispatch();
    const { mode, step, paymentDetails } = useAppSelector(selectPayment);
    console.log("paymentDetails", paymentDetails)

    return (
        <Modal centered size="sm" show={show}>

            {mode === 'Process' && (
                <>
                    {step === 'Start' && (
                        <ConfirmCertifiedFundsAmount
                            onContinue={({checkNumber, checkAmount}) => {
                                dispatch(setPaymentDetails({ type: "CERTIFIED_FUNDS", checkNumber, checkAmount } as CertifiedFundsPaymentDetails));
                                dispatch(setProcessStep('HandleCheck'));
                            }}
                            onCancel={() => dispatch(reset())}
                        />
                    )}
                    {step === 'HandleCheck' && (
                        <ReceiveCheck
                            onContinue={() => dispatch(setProcessStep('Finish'))}
                            onCancel={() => dispatch(reset())}
                        >
                            <CertifiedFundsPaymentDescription />
                        </ReceiveCheck>
                    )}
                </>
            )}
            {mode === 'Void' && (
                <>
                    {step === 'Start' && (
                        <InitiateCheckReturn
                            onContinue={() => dispatch(setProcessStep('HandleCheck'))}
                            onCancel={() => dispatch(reset())}
                        >
                            <CertifiedFundsPaymentDescription />
                        </InitiateCheckReturn>
                    )}
                    {step === 'HandleCheck' && (
                        <ConfirmCheckReturn
                            onContinue={checkReturned => {
                                dispatch(setShouldRefund(!checkReturned));
                                dispatch(setProcessStep(checkReturned ? 'Finish' : 'ConfirmRefund'));
                            }}
                            onCancel={() => dispatch(reset())}
                        />
                    )}
                    {step === 'ConfirmRefund' && (
                        <ConfirmMailRefund
                            refundAmount={(paymentDetails as CertifiedFundsPaymentDetails).checkAmount}
                            onConfirm={() => dispatch(setProcessStep('Finish'))}
                            onCancel={() => dispatch(setProcessStep('HandleCheck'))}
                        />
                    )}
                </>
            )}
            {step === 'Finish' && (
                <ProcessPayment onComplete={() => dispatch(reset())} onCancel={() => dispatch(reset())}>
                    <CertifiedFundsPaymentDescription />
                </ProcessPayment>
            )}
        </Modal>
    );
};
