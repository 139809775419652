import { CardRow } from '@src/components/Card';
import { FlexColumn, FlexRow } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { AsCurrency } from '@src/utils/Formatting';
import { Modal } from 'react-bootstrap';

interface ConfirmMailRefundProps {
    refundAmount: number;
    onConfirm: () => void;
    onCancel: () => void;
}

/**
 * Confirm the intent to issue a mail refund check.
 */
export const ConfirmMailRefund = (props: ConfirmMailRefundProps) => {
    return (
        <>
            <Modal.Header>
                <hzn-heading size="xsmall">Voiding payment</hzn-heading>
            </Modal.Header>
            <Modal.Body>
                <FlexColumn>
                    <CardRow label={<hzn-text tone="subdued">Refund method</hzn-text>} value="Mail refund" />
                    <hzn-divider />
                    <CardRow label={<hzn-text tone="subdued">Refund amount due</hzn-text>} value={<span className="stress">{AsCurrency(props.refundAmount)}</span>} />
                    <HorizonAlert
                        show
                        tone="info"
                        heading="Mail refund will be issued automatically."
                        sub-heading="The refund due to customer will be mailed as a refund check, per CarMax policy. Customers should allow up to 14 business days to receive their refund."
                    />
                </FlexColumn>
            </Modal.Body>
            <Modal.Footer>
                <FlexColumn className="w-100">
                    <HorizonButton variant="primary" onClick={props.onConfirm}>
                        Issue Mail Refund
                    </HorizonButton>
                    <HorizonButton variant="tertiary" onClick={props.onCancel}>
                        Back
                    </HorizonButton>
                </FlexColumn>
            </Modal.Footer>
        </>
    );
};
