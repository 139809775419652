import { BasketDto, PaymentDto } from "@src/api/CheckoutApi";
import Select from "@src/components/horizon/Select";
import { BasketConstants } from "@src/utils/BasketConstants";
import { getSelectablePaymentTypes } from "@src/utils/BasketFns";
import { useMemo } from "react";
import { PaymentTypes } from "./PaymentAddCard";

interface PaymentSelectorProps {
    basket: BasketDto;
    payment: PaymentDto;
    value: string | undefined;
    onValueChanged: (value: PaymentTypes) => void;
}

export const PaymentSelector = ({basket, payment, value, onValueChanged}: PaymentSelectorProps) => {
    const selectabePaymentTypes = useMemo(() => getSelectablePaymentTypes(basket, payment), [basket, payment])

    return (
        <Select
            className={'paymentInput'}
            value={value}
            onSelectionChanged={value => onValueChanged(value as PaymentTypes)}
            label="Payment method"
            placeholder="Select a payment method"
        >
            <option value="CASH" disabled={!selectabePaymentTypes.includes("CASH")}>Cash</option>
            <option value="CERTIFIED_FUNDS" disabled={!selectabePaymentTypes.includes("CERTIFIED_FUNDS")}>Certified Funds</option>
            <option value="CHECK" disabled={!selectabePaymentTypes.includes("CHECK")}>Check (max ${BasketConstants.MaxCombinedPersonalCheckAmount})</option>
            <option value="DEBIT" disabled={!selectabePaymentTypes.includes("DEBIT")}>Debit Card</option>
        </Select>
    );
};
