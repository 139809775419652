import {
    BasketDto,
    BasketLineItemDto,
    CashPaymentDto,
    CheckPaymentDto,
    PaymentDirection,
    PaymentDto,
    PaymentStatus,
} from '@src/api/CheckoutApi';
import { InputErrorMessages } from '@src/components/horizon/Input';
import { PaymentTypes } from '@src/pages/BasketCheckout/Payment/PaymentAddCard/PaymentAddCard';
import { BasketConstants, BasketMessages } from './BasketConstants';

const firstAppraisalPurchaseLineItem = (basket: BasketDto): BasketLineItemDto =>
    basket.lineItems?.find(item => item.lineItemType === 'APPRAISAL_ITEM');

export const getVehicleName = (basket: BasketDto): string =>
    firstAppraisalPurchaseLineItem(basket)?.name ?? 'Not Found';

export const getVehicleVin = (basket: BasketDto): string => firstAppraisalPurchaseLineItem(basket)?.vin ?? 'Not Found';

export const getVehicleStockNumber = (basket: BasketDto): string =>
    firstAppraisalPurchaseLineItem(basket)?.itemId ?? 'Not Found';

export const getPartyId = (basket: BasketDto): string =>
    basket.lineItems[0].participants.find(p => p.roles.includes('SELLER')).id;

export const getTitleHolderName = (basket: BasketDto): string =>
    basket.lineItems[0].participants
        .filter(p => p.roles.includes('TITLE_HOLDER'))
        .map(p => p.name)
        .join(' and ');

const ActivePaymentStatuses: PaymentStatus[] = ['AUTHORIZED', 'NEW', 'PAID', 'PROCESSING'];

const getPaymentsOfDirectionAndType = (
    basket: BasketDto,
    direction: PaymentDirection,
    statuses: PaymentStatus[]
): PaymentDto[] => (basket.payments ?? []).filter(p => p.direction == direction && statuses.includes(p.status));

const totalPayments = (payments: PaymentDto[]) => payments.reduce((acc, p) => acc + (p.amount ?? 0), 0);

export const getActivePayments = (basket: BasketDto): PaymentDto[] =>
    getPaymentsOfDirectionAndType(basket, 'INBOUND', ActivePaymentStatuses);

export const getUnprocessedPayments = (basket: BasketDto): PaymentDto[] =>
    getPaymentsOfDirectionAndType(basket, 'INBOUND', ['NEW']);

export const getProcessedPaymentsTotal = (basket: BasketDto) =>
    totalPayments(getPaymentsOfDirectionAndType(basket, 'INBOUND', ['PAID']));

export const getTotalEquity = (basket: BasketDto) =>
    basket.lineItems.reduce((acc, curr) => acc + (curr.equity ?? 0), 0);

export const getNegativeEquityBalance = (basket: BasketDto): number => {
    // if the customer equity is positive, there is no balance.
    const totalEquity = getTotalEquity(basket);
    if (totalEquity >= 0) return 0;

    // total up all the inbound unprocessed payments (cash, debit, etc.)
    const sumOfUnprocessedPayments = totalPayments(getActivePayments(basket));

    // the customer equity will be negative, e.g. -5000.
    // the unprocessed payment sum will be positive, e.g. 2000
    // the balance should be positive. math.abs(-5000 + 2000) => 3000
    return Math.abs(getTotalEquity(basket) + sumOfUnprocessedPayments);
};

export const getChangeGivenAmount = (basket: BasketDto): number =>
    getPaymentsOfDirectionAndType(basket, 'INBOUND', ['PAID'])
        .filter(p => p.paymentType === 'CASH')
        .reduce((acc, p) => acc + (p as CashPaymentDto).cashCollected - (p as CashPaymentDto).amount, 0);


export const getSelectablePaymentTypes = (basket: BasketDto, payment: PaymentDto | undefined): PaymentTypes[] => {
    const result: PaymentTypes[] = ['CERTIFIED_FUNDS', 'DEBIT']; // always allowed
    const activePayments: PaymentDto[] = getActivePayments(basket);

    // Business rule: there can only be one cash payment
    // Check that there are no saved cash payments, or this is the existing cash payment
    const activeCashPayments = activePayments.filter(p => p.paymentType === 'CASH') as CashPaymentDto[];
    if (activeCashPayments.length === 0 ||
        (activeCashPayments.length === 1 && activeCashPayments[0].id == payment?.id)) {
        result.push('CASH');
    }

    // Business rule: the total combined amount of all personal checks must not exceed $250
    // Check that the total of all check payments is less than 250, or this is one of the active check payments.
    const activeCheckPayments = activePayments.filter(p => p.paymentType === 'CHECK') as CheckPaymentDto[];
    if (activeCheckPayments.reduce((acc, cur) => acc + cur.amount, 0) < BasketConstants.MaxCombinedPersonalCheckAmount ||
        !!activeCheckPayments.find(p => p.id === payment?.id)) {
        result.push('CHECK');
    }

    return result;
};

interface Validations {
    min: number;
    max: number;
    errorMessages: InputErrorMessages;
}

const StandardErrorMessages: InputErrorMessages = {
    valueMissing: BasketMessages.ValidDollarAmtRequiredMessage,
    valid: BasketMessages.ValidDollarAmtRequiredMessage,
    rangeUnderflow: BasketMessages.ValidDollarAmtRequiredMessage,
};

const resolveMaxCheckAmount = (basket: BasketDto, payment: PaymentDto, maxPaymentAmount: number) => {
    const otherCheckPaymentTotalAmount = 
        getActivePayments(basket)                   // get active payments for this basket...
        .filter(p => p.paymentType === "CHECK")     // ...of type "CHECK"
        .map(p => p as CheckPaymentDto)             // (cast for typesafe property names) 
        .filter(p => p.id !== payment?.id)          // ...which aren't this particular payment 
        .reduce((acc, cur) => acc + cur.amount, 0); // ...and sum the payment amount of those checks
    // take the lesser of the the max check amount (capped at $250) and the max payment amount inclusive of all checks
    return Math.min(maxPaymentAmount, BasketConstants.MaxCombinedPersonalCheckAmount - otherCheckPaymentTotalAmount);
}

export const resolvePaymentValidations = (basket: BasketDto, payment: PaymentDto, paymentType: string, balance: number): Validations => {
    // the max allowed amount for this payment. When editing a payment the max allowed should include the amount of the current payment.
    const maxPaymentAmount = balance + (payment?.amount ?? 0);

    switch (paymentType) {
        case 'CHECK': {
            const max = resolveMaxCheckAmount(basket, payment, maxPaymentAmount);
            const rangeOverflow = max === maxPaymentAmount ? BasketMessages.MaxNonCheckAmountMessage : BasketMessages.MaxCheckAmountMessage;
            return {
                min: BasketConstants.MinPaymentAmount,
                max: max,
                errorMessages: { ...StandardErrorMessages, rangeOverflow } 
            };
        }
        default: {
            return {
                min: BasketConstants.MinPaymentAmount,
                max: maxPaymentAmount,
                errorMessages: { ...StandardErrorMessages, rangeOverflow: BasketMessages.MaxNonCheckAmountMessage },
            };
        }
    }
};
