import React from 'react';
import classNames from 'classnames';

interface FlexProps extends React.HTMLAttributes<HTMLDivElement>{
}

const Flex = ({ children, className="", ...attrs }: React.PropsWithChildren<FlexProps>) => {
    const gap = className.includes("gap-") ? undefined : 'gap-3';
    return <div className={classNames('d-flex', className, gap)} {...attrs}>{children}</div>
};

export const FlexRow = ({ children, className, ...attrs }: React.PropsWithChildren<FlexProps>) => (
    <Flex className={classNames("flex-row", className)} {...attrs}>{children}</Flex>
);

export const FlexColumn = ({ children, className, ...attrs }: React.PropsWithChildren<FlexProps>) => (
    <Flex className={classNames("flex-column", className)} {...attrs}>{children}</Flex>
);

export const FlexCenteredItem = ({children}: React.PropsWithChildren<{}>) => ( 
    <FlexColumn className="vh-100 justify-content-center">
        <FlexRow className="w-100 justify-content-center">
            {children}
        </FlexRow>
    </FlexColumn>
);