import React, { lazy, Suspense } from 'react';
import { importRemote } from 'module-federation-import-remote';
import { Card } from '@src/components/Card';
import { BasketDto } from '@src/api/CheckoutApi';
import { getTitleHolderName } from '@src/utils/BasketFns';
import { useParams } from 'react-router-dom';
import { OAUTH_PROXY } from '@src/config';

interface Store {
    id?: string;
    name?: string;
    hasAppraisalCheckout?: boolean;
    hasAppraisalPickup?: boolean;
    hasInstantOfferPhotoVerification?: boolean;
    storeFormat?: string;
}

interface UserInformation {
    displayName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    defaultStoreId?: string;
    userId?: string;
    jobCode?: string;
    roles?: string[];
}

interface HostConfig {
    baseUrl: string;
    authorizationSessionStorageKey?: string;
    cartId: string;
}

const apiConfig = (basket: BasketDto): HostConfig => ({
    baseUrl: `${OAUTH_PROXY.apiProxyUri}/docs`,
    authorizationSessionStorageKey: 'com.unboundid.kmxpos.access_token',
    cartId: '9f3d3ff2-c40b-4897-ba13-c91026dd6e9d'
});

const user = (basket: BasketDto): UserInformation => ({
    displayName: getTitleHolderName(basket),
    firstName: '',
    lastName: '',
    email: '',
});

const store = (storeLocationId: string): Store => ({
    id: storeLocationId,
});

const getRemoteOptions = (url: string) => {
    // By convention:
    // - the Doc remote name related to folder name it is uploaded to. e.g. https://.../docs-mfe-remotes/foo -> remote_foo
    // - the module name is always "Application"
    return {
        scope: `remote_${url.split('/').reverse()[0]}`,
        module: 'Application',
        remoteEntryFileName: 'remote.js',
        bustRemoteEntryCache: true,
        url,
    };
};

export const DocRemote = ({ basket, url }: { basket: BasketDto; url: string }) => {
    const { storeLocationId } = useParams();
    const Remote = lazy(() => importRemote(getRemoteOptions(url)));

    return (
        <Suspense fallback={<hzn-loading />}>
            <Card>
                <Remote apiConfig={apiConfig(basket)} user={user(basket)} store={store(storeLocationId)} />
            </Card>
        </Suspense>
    );
};
