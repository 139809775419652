import { useHandleBasketPaymentActionMutation } from '@src/api/CheckoutApi';
import { useSystemInteractionGetComputerNameQuery } from '@src/api/SystemInteractionApi';
import { FlexColumn, FlexRow } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import { ToTitleCase } from '@src/utils/Formatting';
import { formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

interface ProcessPaymentProps {
    onComplete: () => void;
    onCancel: () => void;
}

/**
 *  Submit the payment details to the API for processing, void, or refund.
 */
export const ProcessPayment = (props: React.PropsWithChildren<ProcessPaymentProps>) => {
    const { storeLocationId, basketId } = useParams();
    const { mode, payment, startTime, paymentDetails, shouldRefund } = useAppSelector(selectPayment);
    const workstationName = useSystemInteractionGetComputerNameQuery('');
    const [processPayment, processPaymentStatus] = useHandleBasketPaymentActionMutation();
    const [isRetry, setIsRetry] = useState(false);

    const paymentAction = mode === "Process" ? 'PROCESS' : shouldRefund ? "REFUND" : "VOID";

    const doProcessPayment = () => {
        processPayment({
            basketId,
            paymentId: payment.id,
            body: {
                paymentAction,
                storeLocationId,
                workstationName: workstationName.data,
                startTime,
                endTime: formatISO(Date.now()),
                paymentDetails,
            },
        })
            .unwrap()
            .then(() => props.onComplete())
            .catch(err => {
                setIsRetry(true);
                console.log('Error processing payment', err);
            });
    };

    useEffect(() => {
        if (!!workstationName.data) {
            doProcessPayment();
        }
    }, [workstationName]);

    return (
        <>
            <Modal.Header>
                <hzn-heading size="xsmall">{ToTitleCase(`${paymentAction}ing`)} payment</hzn-heading>
            </Modal.Header>
            <Modal.Body>
                <FlexColumn>
                    { props.children }
                    {!isRetry && processPaymentStatus.isLoading && (
                        <FlexRow className="justify-content-center">
                            <hzn-loading />
                        </FlexRow>
                    )}
                    <HorizonAlert
                        show={isRetry}
                        heading={`Failed to ${paymentAction.toLowerCase()} the payment.`}
                        tone="caution"
                        sub-heading={`Try ${paymentAction.toLowerCase()}ing the payment again. If the issue is not resolved please notify the MOD.`}
                    ></HorizonAlert>
                </FlexColumn>
            </Modal.Body>
            {isRetry && (
                <Modal.Footer>
                    <FlexColumn className="w-100">
                        <HorizonButton
                            variant="primary"
                            loading={processPaymentStatus.isLoading}
                            onClick={() => doProcessPayment()}
                        >
                            Try Again
                        </HorizonButton>
                        <HorizonButton variant="tertiary" onClick={props.onCancel}>
                            Cancel
                        </HorizonButton>
                    </FlexColumn>
                </Modal.Footer>
            )}
        </>
    );
};
