import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface CardRowProps {
    id?: string;
    label?: string | JSX.Element;
    value?: string | JSX.Element;
    className?: string;
}

export const CardRow = ({ id, label, value, className = '', children }: React.PropsWithChildren<CardRowProps>) => {
    const labelId = useMemo(() => id ?? uuidv4(), [id]);
    return label && value ? (
        <div className={`d-flex flex-row justify-content-between align-items-center gap-4 ${className}`}>
            <label id={labelId}>{label}</label>
            <div aria-labelledby={labelId}>{value}</div>
        </div>
    ) : (
        <div className={`d-flex flex-row justify-content-between align-items-center gap-4 ${className}`}>
            {children}
        </div>
    );
};

interface CardProps {
    title?: string | JSX.Element;
    titleHeader?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    titleSize?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
    className?: string
}

export const Card = ({
    title,
    titleHeader = 'h2',
    titleSize = 'small',
    className,
    children,
}: React.PropsWithChildren<CardProps>) => {
    const body = (
        <div>
            {title && (
                <>
                    <hzn-heading class="p-3" as={titleHeader} size={titleSize}>
                        {title}
                    </hzn-heading>
                </>
            )}
            <div className="p-3">{children}</div>
        </div>
    );
    return (
        <hzn-card class={className} elevation="flat" border-radius="medium" padding="none">
            {body}
        </hzn-card>
    );
};
