import { FlexColumn } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import React from 'react';
import { Modal } from 'react-bootstrap';

interface ReceiveCheckProps {
    onContinue: () => void;
    onCancel: () => void;
}

/**
 *  Pronpt user to scan the check.
 */
export const ReceiveCheck = (props: React.PropsWithChildren<ReceiveCheckProps>) => {
    return (
        <>
            <Modal.Header>
                <hzn-heading size="xsmall">Processing payment</hzn-heading>
            </Modal.Header>
            <Modal.Body>
                <FlexColumn>
                    {props.children}
                    <HorizonAlert
                        show
                        tone="info"
                        heading="Keep check for scanning."
                        sub-heading="Please hold onto the check you are currently processing. You will be prompted to scan a copy of this check in a later step."
                    />
                </FlexColumn>
            </Modal.Body>
            <Modal.Footer>
                <FlexColumn className="w-100">
                    <HorizonButton variant="primary" onClick={props.onContinue}>
                        Process Check
                    </HorizonButton>
                    <HorizonButton variant="tertiary" onClick={props.onCancel}>
                        Cancel
                    </HorizonButton>
                </FlexColumn>
            </Modal.Footer>
        </>
    );
};
