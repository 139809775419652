import { CardRow } from '@src/components/Card';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment, CertifiedFundsPaymentDetails } from '@src/store/PaymentSlice';
import { AsCurrency } from '@src/utils/Formatting';

export const CertifiedFundsPaymentDescription = () => {
    const { mode, payment, paymentDetails } = useAppSelector(selectPayment);
    const certifiedFundsDetails = paymentDetails as CertifiedFundsPaymentDetails;
    const refundDue =
            mode === 'Process' && certifiedFundsDetails?.checkAmount > payment.amount
            ? certifiedFundsDetails.checkAmount - payment.amount
            : undefined;

    return (
        <>
            <CardRow className='pt-3 ps-2 pe-2' label={<hzn-text tone="subdued">Payment method</hzn-text>} value="Certified Funds" />
            <hzn-divider />
            {!certifiedFundsDetails && (
                <CardRow className='ps-2 pe-2' label={<hzn-text tone="subdued">Amount</hzn-text>} value={AsCurrency(payment.amount)} />
            )}
            {certifiedFundsDetails && (
                <>
                    <CardRow className='ps-2 pe-2' label={<hzn-text tone="subdued">Check Amount</hzn-text>} value={AsCurrency(certifiedFundsDetails.checkAmount)} />
                    <hzn-divider />
                    <CardRow className='ps-2 pe-2' label={<hzn-text tone="subdued">Amount applied to balance</hzn-text>} value={AsCurrency(payment.amount)} />
                    <hzn-divider />
                    {refundDue && (
                        <>
                            <CardRow className='ps-2 pe-2' label={<hzn-text tone="subdued">Mail refund due</hzn-text>} value={AsCurrency(refundDue)} />
                            <hzn-divider />
                        </>
                    )}
                    <CardRow className='ps-2 pe-2' label={<hzn-text tone="subdued">Check Number</hzn-text>} value={certifiedFundsDetails.checkNumber} />
                    <HorizonAlert
                        show={!!refundDue}
                        heading="Mail refund will be issued automatically."
                        tone="info"
                        sub-heading="The refund due to customer will be mailed as a refund check, per CarMax policy. Customers should allow up to 14 business days to receive their refund."
                    ></HorizonAlert>
                </>
            )}
        </>
    );
};
