export const BasketConstants = {
    MaxCombinedPersonalCheckAmount: 250,
    MinPaymentAmount: 0.01,
};

export const BasketMessages = {
    ValidDollarAmtRequiredMessage: 'A valid dollar amount is required.',
    MaxCheckAmountMessage: `The combined amount of all checks cannot exceed $${BasketConstants.MaxCombinedPersonalCheckAmount}.`,
    MaxNonCheckAmountMessage: 'Amount cannot exceed outstanding balance.',
}
