import { FlexColumn } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import RadioGroup, { RadioGroupHandle } from '@src/components/horizon/RadioGroup';
import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface ConfirmCheckReturnProps {
    onContinue: (checkReturned: boolean) => void;
    onCancel: () => void;
}

/**
 * Prompt the user to confirm that the original check was returned to the customer.
 * If it wasn't, show an alert that the customer will be issued a mail refund check. 
 */
export const ConfirmCheckReturn = (props: ConfirmCheckReturnProps) => {
    const radioRef = useRef<RadioGroupHandle>();
    const [checkReturned, setCheckReturned] = useState<boolean>(undefined);

    const onConfirm = () => {
        if (!radioRef.current.validate()) return;
        props.onContinue(checkReturned);
    };

    return (
        <>
            <Modal.Header>
                <hzn-heading size="xsmall">Voiding payment</hzn-heading>
            </Modal.Header>
            <Modal.Body>
                <FlexColumn>
                    <RadioGroup
                        ref={radioRef}
                        required
                        horizontal
                        legend="Was the check returned to the customer?"
                        value={checkReturned ? checkReturned.toString() : ''}
                        onValueChanged={value => setCheckReturned(value === "true")}
                    >
                        <hzn-radio value="true">Yes</hzn-radio>
                        <hzn-radio value="false">No</hzn-radio>
                    </RadioGroup>
                    <HorizonAlert
                        show={checkReturned === false}
                        tone="info"
                        heading="Issue a mail refund check."
                        sub-heading="The refund due to customer will be mailed as a refund check, per CarMax policy. Please allow up to 14 business days for the refund to arrive. Continue to issue a mail refund check."
                    />
                </FlexColumn>
            </Modal.Body>
            <Modal.Footer>
                <FlexColumn className="w-100">
                    <HorizonButton variant="primary" onClick={() => onConfirm()}>
                        Continue
                    </HorizonButton>
                    <HorizonButton variant="tertiary" onClick={props.onCancel}>
                        Cancel
                    </HorizonButton>
                </FlexColumn>
            </Modal.Footer>
        </>
    );
};
