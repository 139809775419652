import React from 'react';
import { logger } from './common/logger';
import { OAuthErrorModal } from './components/OAuthErrorModal';
import OAuthCallback, { OAPErrorPresentation } from './containers/OAuthCallback';
import { canAccessApp } from './helpers/AuthzCheck';
import Claims from './models/Claims';
import { FlexCenteredItem, FlexColumn, FlexRow } from './components/Flex';
import { authorizationUrl } from './helpers/OAuthHelpers';
import { AppRoutes, GatewayRoutes } from './Routes';
import { guid } from './helpers/GenerationHelpers';
import { Card, CardRow } from './components/Card';
import { HorizonAlert } from './components/horizon/HorizonAlert';
import { ENVIRONMENT } from './config';

export const Host = () => {
    const [error, setError] = React.useState<OAPErrorPresentation>();

    // If there's an error, show it.
    if (error) return <OAuthErrorModal error={error} showModal={true} />;

    // If this is an Electron launch request, process it.
    if (window.location.pathname.toLowerCase().startsWith('/gateway')) {
        console.log('Redirecting to Electron launcher gateway.');
        return <GatewayRoutes />;
    }

    // Resolve OAuth query parameters, if any. If this is an OAuth redirect, process it.
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('code')) return <OAuthCallback onErrorChange={setError} />;

    // Resolve existing user claims, if any.
    const claims = JSON.parse(sessionStorage.getItem('com.unboundid.kmxpos.profile')) as Claims;

    // If the user does not have claims in session storage, redirect them to SSO login.
    if (!claims) {
        console.log('Redirecting to SSO Login.');
        const nonce = guid();
        sessionStorage.setItem('com.unboundid.kmxpos.nonce', nonce);
        window.location.replace(authorizationUrl(nonce).toString());
        return (
            <FlexCenteredItem>
                <hzn-loading />
            </FlexCenteredItem>
        );
    }

    // If the user does have claims but they don't role-based access to Checkout, show a message.
    if (!canAccessApp(claims)) {
        console.log("You have claims but aren't authorized to access this application.");
        return (
            <FlexCenteredItem>
                <Card title="Access Denied" className="w-33">
                    <FlexColumn>
                        <CardRow>You are not authorized to access this application.</CardRow>
                        <HorizonAlert
                            show
                            tone="critical"
                            heading="Missing required claims"
                            sub-heading={`Contact the ${ENVIRONMENT === 'prod' ? 'Manager on Duty' : 'Pathfinders team'} to request access.`}
                        />
                        <CardRow>EMP ID: {claims.sub}</CardRow>
                    </FlexColumn>
                </Card>
            </FlexCenteredItem>
        );
    }

    // The user has claims and can access the app.
    console.log('You have claims and are authorized to access the application.');
    logger.setUserContext(claims);
    return <AppRoutes claims={claims} />;
};
