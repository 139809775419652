import { useEventListener } from '../../hooks/useEventListener';
import { CustomElement } from '@horizon/common/types';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { HznRadioGroupHTML, HznRadioGroupInstance } from '@horizon/radios/types';
import { HznRadioGroup } from '@horizon/radios/radio-group/radio-group';

export interface RadioGroupHandle {
    validate: () => boolean;
}

interface RadioGroupProps {
    className?: string;
    onValueChanged: (value: string | undefined) => void;
}

const RadioGroup = forwardRef<RadioGroupHandle, React.PropsWithChildren<RadioGroupProps & CustomElement<HznRadioGroupInstance, HznRadioGroupHTML>>>(
    ({ children, className, onValueChanged, ...attrs }, fwdRef) => {
        const inputRef = useRef<HznRadioGroup>();

        const onChange = useCallback(
            (target: HznRadioGroup) => onValueChanged(target.value === '' ? undefined : target.value),
            [onValueChanged]
        );

        // useEventListener(inputRef, 'click', onChange);
        useEventListener(inputRef, 'input', onChange);
        useEventListener(inputRef, 'change', onChange);

        // hook for external validation invocation
        useImperativeHandle(fwdRef, () => ({
            validate: () => {
                if (!inputRef.current) return false;
                inputRef.current.checkValidity();
                return inputRef.current.validity.valid;
            },
        }));

        return (
            <hzn-radio-group ref={inputRef} class={className} {...attrs}>
                {children}
            </hzn-radio-group>
        );
    }
);

export default RadioGroup;
